import React from "react";
import Page from "../components/Layout/Page";
import { graphql } from "gatsby";
import MarkdownFileCard from "../components/Elements/MarkdownFileCard";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";

const Blog = ({ data: { blogposts } }) => (
    <Page title={pageTitles.blog}>
        <PageHeader
            color={pageColors.blog}
            counts={[`${blogposts.totalCount} blogposts`]}
        >
            I like to occasionally write articles about things that peak my
            interest at work, or opinions that I form over the years.
        </PageHeader>
        {blogposts.nodes.map((blogpost) => (
            <MarkdownFileCard
                key={blogpost.id}
                to={blogpost.fields.slug}
                blogpost={blogpost}
                className={`dark-hover:bg-${pageColors.blog}-800 dark:bg-${pageColors.blog}-900`}
                titleColor={`text-${pageColors.blog}-600 dark:text-white`}
            />
        ))}
    </Page>
);

export const query = graphql`
    {
        blogposts: allMarkdownRemark(
            filter: { fields: { type: { eq: "blogposts" } } }
            sort: { order: DESC, fields: frontmatter___date }
        ) {
            totalCount
            nodes {
                id
                excerpt(pruneLength: 280)
                timeToRead
                fields {
                    slug
                    type
                }
                frontmatter {
                    excerpt
                    title
                    date(formatString: "DD MMMM YYYY")
                    category
                    lang
                    cover {
                        childImageSharp {
                            fluid(quality: 90) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Blog;
