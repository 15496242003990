import GatsbyImage from "gatsby-image";
import React from "react";
import { Link } from "gatsby";

const LanguageFlag = ({ lang }) => <span>{{ fr: "🇫🇷", en: "🇺🇸" }[lang]}</span>;

const MarkdownFileCard = ({
    to,
    blogpost: file,
    className = "",
    titleColor = "text-indigo-500 dark:text-indigo-300",
}) => (
    <Link to={to} className={"no-underline"}>
        <article
            className={`p-5 md:p-10 lg:p-20 flex flex-col xl:flex-row transition bg-white hover:bg-gray-200 dark:text-white items-start ${className}`}
        >
            {file.frontmatter.cover && (
                <GatsbyImage
                    className={
                        "bg-white rounded shadow-xl w-full xl:h-auto xl:w-1/3"
                    }
                    style={{ maxHeight: 500 }}
                    fluid={{
                        ...file.frontmatter.cover.childImageSharp.fluid,
                        aspectRatio: file.fields.type === "blogposts" ? 1.5 : 1,
                    }}
                />
            )}
            <div className={"flex-1 py-5 xl:py-0 xl:px-10"}>
                <h1
                    className={
                        "font-hairline m-0 text-2xl md:text-4xl " + titleColor
                    }
                >
                    {file.frontmatter.title}
                </h1>
                <small className={`text-gray-500`}>
                    {file.frontmatter.date}
                    {file.frontmatter.category && (
                        <strong> - {file.frontmatter.category}</strong>
                    )}
                    <br />
                    <strong>{file.timeToRead} mn</strong> to read
                    <br />
                    <LanguageFlag lang={file.frontmatter.lang} />
                </small>
                <p
                    className={`text-gray-700 dark:text-white mt-5 text-lg w-full xl:w-2/3`}
                >
                    {file.frontmatter.excerpt || file.excerpt}
                </p>
            </div>
        </article>
    </Link>
);

export default MarkdownFileCard;
